<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <v-card-title class="display-2 mb-4">{{$t('profile.activities.entry.upload')}}</v-card-title>
      <v-card-text>
        {{$t('profile.activities.upload-message')}}
      </v-card-text>

      <ActivityUploadForm class="px-4 mb-4" event-id=""></ActivityUploadForm>
      <br/>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import activityService from "@/services/activityService";
import ActivityUploadForm from '@/components/ActivityUploadForm.vue';
import Header from './_Header.vue';

export default {
  name: "Activities",
  components: {
    ActivityUploadForm,
    Header,
  },
  props: {
  },
  data() {
    return {
      activities: null,
      breadcrumbItems: [
        { text: this.$t('profile.title'), href: '/profile' },
        { text: this.$t('profile.activities.title'), href: '/profile/activities' },
        { text: this.$t('profile.activities.entry.upload'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
      }
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

